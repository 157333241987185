// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Api from "../../../../api/Api.res.js";
import * as Url from "../../../../utils/Url.res.js";
import * as Char from "../../../../libs/Char.res.js";
import * as Hooks from "../../../../libs/Hooks.res.js";
import * as Label from "../../../../styleguide/forms/Label/Label.res.js";
import * as React from "react";
import * as Button from "../../../../styleguide/components/Button/Button.res.js";
import * as Select from "../../../../styleguide/forms/Select/Select.res.js";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Checkbox from "../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Debounce from "rescript-debounce/src/Debounce.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Textarea from "../../../../styleguide/forms/Textarea/Textarea.res.js";
import * as ReactForm from "../../../../libs/ReactForm.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as DatePicker from "../../../../styleguide/forms/DatePicker/DatePicker.res.js";
import * as Visibility from "../../../../libs/Visibility.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as $$Notification from "../../../../bindings/Notification.res.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as ProjectWizard from "../_components/ProjectWizard/ProjectWizard.res.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Routes_Project from "../../../../routes/common/Routes_Project.res.js";
import AddDays from "date-fns/addDays";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectWizardPanel from "../_components/ProjectWizardPanel/ProjectWizardPanel.res.js";
import * as ReactNotifications from "react-notifications";
import * as SignInModalContainer from "../../../../styleguide/modals/SignInModal/SignInModalContainer.res.js";
import * as ProjectWizardNavigation from "../_components/ProjectWizardNavigation/ProjectWizardNavigation.res.js";
import * as CreateCloudProjectIaasCloudStorageConfigurationForm from "./CreateCloudProjectIaasCloudStorageConfigurationForm.res.js";
import * as CreateCloudProjectIaasCloudStorageConfigurationScss from "./CreateCloudProjectIaasCloudStorageConfiguration.scss";
import * as CreateCloudProjectIaasCloudStorageConfiguration__DropdownField from "./CreateCloudProjectIaasCloudStorageConfiguration__DropdownField.res.js";
import * as CreateCloudProjectIaasCloudStorageConfiguration__InputWithUnitSelector from "./CreateCloudProjectIaasCloudStorageConfiguration__InputWithUnitSelector.res.js";

var css = CreateCloudProjectIaasCloudStorageConfigurationScss;

function domId(field, at) {
  return "cloud-project-cloud-iaas-storage-configuration-" + (field + ("-" + String(at)));
}

function configurationTitle(form, at) {
  var validators = CreateCloudProjectIaasCloudStorageConfigurationForm.validators.configurations.fields;
  var x = form.configurationRawStorageCapacityResult(at);
  var result = x !== undefined ? x : validators.rawStorageCapacity.validate(form.input, at);
  var rawStorageCapacity;
  rawStorageCapacity = result.TAG === "Ok" ? result._0 : undefined;
  var configuration = form.input.configurations[at];
  var constructed = Belt_Array.reduce([
          rawStorageCapacity,
          configuration.diskType,
          configuration.connectivityMethod,
          configuration.region
        ], [], (function (acc, item) {
            if (item !== undefined) {
              acc.push(item);
              return acc;
            } else {
              return acc;
            }
          })).join(", ");
  if (constructed === "") {
    return "#" + (String(at + 1 | 0) + (Char.mdash + "IaaS Storage Configuration"));
  } else {
    return constructed;
  }
}

function add(x, status) {
  return Belt_Array.concat(x, [status]);
}

function remove(x, index) {
  return Belt_Array.keepWithIndex(x, (function (param, idx) {
                return index !== idx;
              }));
}

function toggle(x, index) {
  return Belt_Array.mapWithIndex(x, (function (idx, visibility) {
                if (index === idx) {
                  return Visibility.toggle(visibility);
                } else {
                  return visibility;
                }
              }));
}

function expandAll(x) {
  return Belt_Array.map(x, (function (param) {
                return "Shown";
              }));
}

var Visibilities = {
  add: add,
  remove: remove,
  toggle: toggle,
  expandAll: expandAll
};

function validate(form) {
  Belt_Array.forEachWithIndex(form.input.configurations, (function (ix, param) {
          form.blurConfigurationStartDate(ix);
        }));
}

function results(form) {
  return Belt_Array.mapWithIndex(form.input.configurations, (function (ix, param) {
                return form.configurationStartDateResult(ix);
              }));
}

function isValid(results) {
  return Belt_Array.every(results, (function (result) {
                if (result !== undefined && result.TAG === "Ok") {
                  return true;
                } else {
                  return false;
                }
              }));
}

var ValidateSaveAndContinueLaterForm = {
  validate: validate,
  results: results,
  isValid: isValid
};

var persistDraft = Debounce.make(900, (function (param) {
        if (param[1].TAG === "Draft") {
          return $$Promise.wait(Api.saveCloudProjectIaasCloudStorageConfigurationDraft(param[0]), (function (x) {
                        if (x.TAG === "Ok") {
                          return $$Notification.NotificationManager.success("Draft saved!", "", 1000);
                        }
                        SentryLogger.error1({
                              rootModule: "CreateCloudProjectIaasCloudStorageConfiguration",
                              subModulePath: {
                                hd: "Api",
                                tl: /* [] */0
                              },
                              value: "persistDraft",
                              fullPath: "CreateCloudProjectIaasCloudStorageConfiguration.Api.persistDraft"
                            }, "SaveCloudProjectIaasCloudStorageConfigurationDraft::Error", [
                              "Error",
                              x._0
                            ]);
                        $$Notification.NotificationManager.error("Something went wrong trying to save the draft", "", 1000);
                      }));
        }
        
      }));

function submitForm(data, context, onFailure) {
  if (context.TAG === "Draft") {
    return $$Promise.wait(Api.saveCloudProjectIaasCloudStorageConfiguration(data), (function (x) {
                  if (x.TAG === "Ok") {
                    return Url.visit(Routes_Project.Cloud.Provider.newSelector("IaasStorage"));
                  }
                  SentryLogger.error1({
                        rootModule: "CreateCloudProjectIaasCloudStorageConfiguration",
                        subModulePath: {
                          hd: "Api",
                          tl: /* [] */0
                        },
                        value: "submitForm",
                        fullPath: "CreateCloudProjectIaasCloudStorageConfiguration.Api.submitForm"
                      }, "SaveCloudProjectIaasCloudStorageConfiguration::Error", [
                        "Error",
                        x._0
                      ]);
                  onFailure();
                  $$Notification.NotificationManager.error("Something went wrong trying to save the project", "", 1000);
                }));
  }
  var projectId = context.projectId;
  $$Promise.wait(Api.updateCloudProjectIaasCloudStorageConfiguration(projectId, data), (function (x) {
          if (x.TAG === "Ok") {
            return Url.visit(Routes_Project.Cloud.Provider.editSelector(projectId, "IaasStorage"));
          }
          SentryLogger.error1({
                rootModule: "CreateCloudProjectIaasCloudStorageConfiguration",
                subModulePath: {
                  hd: "Api",
                  tl: /* [] */0
                },
                value: "submitForm",
                fullPath: "CreateCloudProjectIaasCloudStorageConfiguration.Api.submitForm"
              }, "UpdateCloudProjectIaasCloudStorageConfiguration::Error", [
                "Error",
                x._0
              ]);
          onFailure();
          $$Notification.NotificationManager.error("Something went wrong trying to update the project", "", 1000);
        }));
}

function saveDraftProjectAndContinueLater(form, context) {
  if (context.TAG !== "Draft") {
    return $$Promise.wait(Api.updateCloudIaasStorageDraftProjectAndContinueLater(context.projectId, form.input), (function (x) {
                  if (x.TAG === "Ok") {
                    return Url.visit(Routes_Project.index);
                  }
                  SentryLogger.error1({
                        rootModule: "CreateCloudProjectIaasCloudStorageConfiguration",
                        subModulePath: {
                          hd: "Api",
                          tl: /* [] */0
                        },
                        value: "saveDraftProjectAndContinueLater",
                        fullPath: "CreateCloudProjectIaasCloudStorageConfiguration.Api.saveDraftProjectAndContinueLater"
                      }, "UpdateCloudProjectIaasStorageCloudConfiguration::Error", [
                        "Error",
                        x._0
                      ]);
                  $$Notification.NotificationManager.error("Something went wrong trying to update the project", "", 1000);
                }));
  }
  validate(form);
  if (isValid(results(form))) {
    return $$Promise.wait(Api.saveCloudDraftProjectAndContinueLater("Cloud", {
                    TAG: "Cloud",
                    _0: "IaasStorage"
                  }, "Draft"), (function (x) {
                  if (x.TAG === "Ok") {
                    return Url.visit(Routes_Project.index);
                  }
                  SentryLogger.error1({
                        rootModule: "CreateCloudProjectIaasCloudStorageConfiguration",
                        subModulePath: {
                          hd: "Api",
                          tl: /* [] */0
                        },
                        value: "saveDraftProjectAndContinueLater",
                        fullPath: "CreateCloudProjectIaasCloudStorageConfiguration.Api.saveDraftProjectAndContinueLater"
                      }, "saveDraftProjectAndContinueLater::saveProjectDraft::Error", [
                        "Error",
                        x._0
                      ]);
                  $$Notification.NotificationManager.error("Something went wrong trying to save the draft", "", 1000);
                }));
  }
  
}

var Api$1 = {
  persistDraft: persistDraft,
  submitForm: submitForm,
  saveDraftProjectAndContinueLater: saveDraftProjectAndContinueLater
};

function CreateCloudProjectIaasCloudStorageConfiguration(props) {
  var userLoginStatus = props.userLoginStatus;
  var context = props.context;
  var mobile = props.mobile;
  var options = props.options;
  var match = React.useState(function () {
        return "Hidden";
      });
  var toggleSignInModal = match[1];
  var signInModal = match[0];
  var initialInput = React.useMemo((function () {
          if (context.TAG !== "Draft") {
            return {
                    configurations: context.data
                  };
          }
          var data = context.data;
          if (data !== undefined) {
            return {
                    configurations: data
                  };
          } else {
            return {
                    configurations: [CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.empty()]
                  };
          }
        }), []);
  var form = CreateCloudProjectIaasCloudStorageConfigurationForm.useForm(initialInput, (function (output, form) {
          submitForm(output, context, form.notifyOnFailure);
        }));
  var initialState = React.useMemo((function () {
          var data;
          if (context.TAG === "Draft") {
            var data$1 = context.data;
            if (data$1 === undefined) {
              return {
                      panelsVisibility: ["Shown"],
                      featuresVisibility: ["Hidden"],
                      accessTypesVisibility: ["Hidden"],
                      certificationsVisibility: ["Hidden"],
                      additionalRequirementsVisibility: ["Hidden"],
                      userLoginStatus: userLoginStatus
                    };
            }
            data = data$1;
          } else {
            data = context.data;
          }
          return {
                  panelsVisibility: Belt_Array.map(data, (function (param) {
                          return "Shown";
                        })),
                  featuresVisibility: Belt_Array.map(data, (function (param) {
                          return "Hidden";
                        })),
                  accessTypesVisibility: Belt_Array.map(data, (function (param) {
                          return "Hidden";
                        })),
                  certificationsVisibility: Belt_Array.map(data, (function (param) {
                          return "Hidden";
                        })),
                  additionalRequirementsVisibility: Belt_Array.map(data, (function (param) {
                          return "Hidden";
                        })),
                  userLoginStatus: userLoginStatus
                };
        }), []);
  var match$1 = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            if (action === "AddPanel") {
              return {
                      TAG: "UpdateWithSideEffects",
                      _0: {
                        panelsVisibility: Belt_Array.concat(state.panelsVisibility, ["Shown"]),
                        featuresVisibility: Belt_Array.concat(state.featuresVisibility, ["Hidden"]),
                        accessTypesVisibility: Belt_Array.concat(state.accessTypesVisibility, ["Hidden"]),
                        certificationsVisibility: Belt_Array.concat(state.certificationsVisibility, ["Hidden"]),
                        additionalRequirementsVisibility: Belt_Array.concat(state.additionalRequirementsVisibility, ["Hidden"]),
                        userLoginStatus: state.userLoginStatus
                      },
                      _1: (function (param) {
                          form.addConfiguration(CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.empty());
                        })
                    };
            } else {
              return {
                      TAG: "Update",
                      _0: {
                        panelsVisibility: Belt_Array.map(state.panelsVisibility, (function (param) {
                                return "Shown";
                              })),
                        featuresVisibility: Belt_Array.map(state.featuresVisibility, (function (param) {
                                return "Shown";
                              })),
                        accessTypesVisibility: Belt_Array.map(state.accessTypesVisibility, (function (param) {
                                return "Shown";
                              })),
                        certificationsVisibility: Belt_Array.map(state.certificationsVisibility, (function (param) {
                                return "Shown";
                              })),
                        additionalRequirementsVisibility: Belt_Array.map(state.additionalRequirementsVisibility, (function (param) {
                                return "Shown";
                              })),
                        userLoginStatus: state.userLoginStatus
                      }
                    };
            }
          }
          switch (action.TAG) {
            case "RemovePanel" :
                var at = action.at;
                return {
                        TAG: "Update",
                        _0: {
                          panelsVisibility: remove(state.panelsVisibility, at),
                          featuresVisibility: remove(state.featuresVisibility, at),
                          accessTypesVisibility: remove(state.accessTypesVisibility, at),
                          certificationsVisibility: remove(state.certificationsVisibility, at),
                          additionalRequirementsVisibility: remove(state.additionalRequirementsVisibility, at),
                          userLoginStatus: state.userLoginStatus
                        }
                      };
            case "TogglePanelVisibility" :
                return {
                        TAG: "Update",
                        _0: {
                          panelsVisibility: toggle(state.panelsVisibility, action.at),
                          featuresVisibility: state.featuresVisibility,
                          accessTypesVisibility: state.accessTypesVisibility,
                          certificationsVisibility: state.certificationsVisibility,
                          additionalRequirementsVisibility: state.additionalRequirementsVisibility,
                          userLoginStatus: state.userLoginStatus
                        }
                      };
            case "ToggleFeaturesVisibility" :
                return {
                        TAG: "Update",
                        _0: {
                          panelsVisibility: state.panelsVisibility,
                          featuresVisibility: toggle(state.featuresVisibility, action.at),
                          accessTypesVisibility: state.accessTypesVisibility,
                          certificationsVisibility: state.certificationsVisibility,
                          additionalRequirementsVisibility: state.additionalRequirementsVisibility,
                          userLoginStatus: state.userLoginStatus
                        }
                      };
            case "ToggleAccessTypesVisibility" :
                return {
                        TAG: "Update",
                        _0: {
                          panelsVisibility: state.panelsVisibility,
                          featuresVisibility: state.featuresVisibility,
                          accessTypesVisibility: toggle(state.accessTypesVisibility, action.at),
                          certificationsVisibility: state.certificationsVisibility,
                          additionalRequirementsVisibility: state.additionalRequirementsVisibility,
                          userLoginStatus: state.userLoginStatus
                        }
                      };
            case "ToggleCertificationsVisibility" :
                return {
                        TAG: "Update",
                        _0: {
                          panelsVisibility: state.panelsVisibility,
                          featuresVisibility: state.featuresVisibility,
                          accessTypesVisibility: state.accessTypesVisibility,
                          certificationsVisibility: toggle(state.certificationsVisibility, action.at),
                          additionalRequirementsVisibility: state.additionalRequirementsVisibility,
                          userLoginStatus: state.userLoginStatus
                        }
                      };
            case "ToggleAdditionalRequirementsVisibility" :
                return {
                        TAG: "Update",
                        _0: {
                          panelsVisibility: state.panelsVisibility,
                          featuresVisibility: state.featuresVisibility,
                          accessTypesVisibility: state.accessTypesVisibility,
                          certificationsVisibility: state.certificationsVisibility,
                          additionalRequirementsVisibility: toggle(state.additionalRequirementsVisibility, action.at),
                          userLoginStatus: state.userLoginStatus
                        }
                      };
            case "ToggleLoginStatus" :
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          panelsVisibility: state.panelsVisibility,
                          featuresVisibility: state.featuresVisibility,
                          accessTypesVisibility: state.accessTypesVisibility,
                          certificationsVisibility: state.certificationsVisibility,
                          additionalRequirementsVisibility: state.additionalRequirementsVisibility,
                          userLoginStatus: action._0
                        },
                        _1: (function (param) {
                            saveDraftProjectAndContinueLater(form, context);
                          })
                      };
            
          }
        }));
  var dispatch = match$1[1];
  var state = match$1[0];
  var match$2 = state.userLoginStatus;
  var tmp;
  if (match$2 === "LoggedIn") {
    var tmp$1;
    tmp$1 = context.TAG === "Draft" ? "Next" : "Save and Next";
    var match$3 = form.status;
    var tmp$2;
    tmp$2 = typeof match$3 !== "object" || match$3.TAG !== "Submitting" ? "Enabled" : "Busy";
    tmp = [
      tmp$1,
      (function () {
          form.submit();
        }),
      tmp$2
    ];
  } else {
    tmp = [
      "Next",
      (function () {
          toggleSignInModal(function (param) {
                return Visibility.toggle(signInModal);
              });
        }),
      "Enabled"
    ];
  }
  var tmp$3;
  tmp$3 = context.TAG === "Draft" ? [
      "Back",
      (function () {
          Url.visit(Routes_Project.Cloud.$$new);
        }),
      "Enabled"
    ] : [
      "Cancel",
      (function () {
          Url.visit(Routes_Project.index);
        }),
      "Enabled"
    ];
  var match$4 = state.userLoginStatus;
  var tmp$4;
  tmp$4 = match$4 === "LoggedIn" && props.projectStatus === "Draft" ? [
      "Save and Continue Later",
      (function () {
          saveDraftProjectAndContinueLater(form, context);
        })
    ] : undefined;
  var tmp$5;
  tmp$5 = signInModal === "Shown" ? JsxRuntime.jsx(SignInModalContainer.make, {
          close: (function () {
              toggleSignInModal(function (param) {
                    return Visibility.toggle(signInModal);
                  });
            }),
          callback: (function () {
              dispatch({
                    TAG: "ToggleLoginStatus",
                    _0: "LoggedIn"
                  });
              Url.visit(Routes_Project.IaasStorage.$$new);
            }),
          setUserData: props.setUserData,
          subTitle: "Sign In to save the project detail and continue later"
        }) : null;
  return JsxRuntime.jsxs(ProjectWizard.Container.make, {
              children: [
                JsxRuntime.jsx(ReactNotifications.NotificationContainer, {}),
                JsxRuntime.jsxs(ProjectWizard.Content.make, {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("h1", {
                                    children: "Step 1: Configure Your Cloud Storage",
                                    className: css.title
                                  }),
                              className: css.tooltipAndHeadingWrapper
                            }),
                        JsxRuntime.jsx(ProjectWizard.H2.make, {
                              children: "Select Your Cloud Storage Type and Amount"
                            }),
                        Belt_Array.mapWithIndex(form.input.configurations, (function (configurationIndex, configuration) {
                                var visibility = state.panelsVisibility[configurationIndex];
                                var match = form.input.configurations;
                                var tmp;
                                if (visibility === "Shown") {
                                  var result = form.configurationStartDateResult(configurationIndex);
                                  var domId$1 = domId("service-start-date", configurationIndex);
                                  var contractLength = configuration.contractLength;
                                  var domId$2 = domId("description", configurationIndex);
                                  var visibility$1 = state.featuresVisibility[configurationIndex];
                                  var tmp$1;
                                  tmp$1 = visibility$1 === "Shown" ? JsxRuntime.jsx(ProjectWizardPanel.ZebraGrid.make, {
                                          items: options.features,
                                          mobile: mobile,
                                          children: (function (feature) {
                                              var domId$3 = domId("features-" + feature, configurationIndex);
                                              return JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                                          id: domId$3,
                                                          size: "MD",
                                                          checked: Belt_SetString.has(configuration.features, feature),
                                                          containerClassName: css.checkbox,
                                                          onChange: (function ($$event) {
                                                              var value = ReactForm.checked($$event) ? Belt_SetString.add(configuration.features, feature) : Belt_SetString.remove(configuration.features, feature);
                                                              form.updateConfigurationFeatures(configurationIndex, (function (input, value) {
                                                                      return CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                    return {
                                                                                            key: configuration.key,
                                                                                            startDate: configuration.startDate,
                                                                                            contractLength: configuration.contractLength,
                                                                                            rawStorageCapacity: configuration.rawStorageCapacity,
                                                                                            diskType: configuration.diskType,
                                                                                            connectivityMethod: configuration.connectivityMethod,
                                                                                            getRequests: configuration.getRequests,
                                                                                            getRequestsUnit: configuration.getRequestsUnit,
                                                                                            putRequests: configuration.putRequests,
                                                                                            putRequestsUnit: configuration.putRequestsUnit,
                                                                                            transferOut: configuration.transferOut,
                                                                                            transferOutUnit: configuration.transferOutUnit,
                                                                                            region: configuration.region,
                                                                                            cloudType: configuration.cloudType,
                                                                                            description: configuration.description,
                                                                                            features: value,
                                                                                            accessTypes: configuration.accessTypes,
                                                                                            certifications: configuration.certifications,
                                                                                            additionalRequirements: configuration.additionalRequirements
                                                                                          };
                                                                                  }));
                                                                    }), value);
                                                              persistDraft([
                                                                    CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                            return {
                                                                                    key: configuration.key,
                                                                                    startDate: configuration.startDate,
                                                                                    contractLength: configuration.contractLength,
                                                                                    rawStorageCapacity: configuration.rawStorageCapacity,
                                                                                    diskType: configuration.diskType,
                                                                                    connectivityMethod: configuration.connectivityMethod,
                                                                                    getRequests: configuration.getRequests,
                                                                                    getRequestsUnit: configuration.getRequestsUnit,
                                                                                    putRequests: configuration.putRequests,
                                                                                    putRequestsUnit: configuration.putRequestsUnit,
                                                                                    transferOut: configuration.transferOut,
                                                                                    transferOutUnit: configuration.transferOutUnit,
                                                                                    region: configuration.region,
                                                                                    cloudType: configuration.cloudType,
                                                                                    description: configuration.description,
                                                                                    features: value,
                                                                                    accessTypes: configuration.accessTypes,
                                                                                    certifications: configuration.certifications,
                                                                                    additionalRequirements: configuration.additionalRequirements
                                                                                  };
                                                                          })),
                                                                    context
                                                                  ]);
                                                            }),
                                                          onBlur: (function (param) {
                                                              form.blurConfigurationFeatures(configurationIndex);
                                                            }),
                                                          children: feature
                                                        }, domId$3);
                                            })
                                        }) : null;
                                  var visibility$2 = state.accessTypesVisibility[configurationIndex];
                                  var tmp$2;
                                  tmp$2 = visibility$2 === "Shown" ? JsxRuntime.jsx(ProjectWizardPanel.ZebraGrid.make, {
                                          items: options.accessTypes,
                                          mobile: mobile,
                                          children: (function (accessType) {
                                              var domId$3 = domId("accessType-" + accessType, configurationIndex);
                                              return JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                                          id: domId$3,
                                                          size: "MD",
                                                          checked: Belt_SetString.has(configuration.accessTypes, accessType),
                                                          containerClassName: css.checkbox,
                                                          onChange: (function ($$event) {
                                                              var value = ReactForm.checked($$event) ? Belt_SetString.add(configuration.accessTypes, accessType) : Belt_SetString.remove(configuration.accessTypes, accessType);
                                                              form.updateConfigurationAccessTypes(configurationIndex, (function (input, value) {
                                                                      return CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                    return {
                                                                                            key: configuration.key,
                                                                                            startDate: configuration.startDate,
                                                                                            contractLength: configuration.contractLength,
                                                                                            rawStorageCapacity: configuration.rawStorageCapacity,
                                                                                            diskType: configuration.diskType,
                                                                                            connectivityMethod: configuration.connectivityMethod,
                                                                                            getRequests: configuration.getRequests,
                                                                                            getRequestsUnit: configuration.getRequestsUnit,
                                                                                            putRequests: configuration.putRequests,
                                                                                            putRequestsUnit: configuration.putRequestsUnit,
                                                                                            transferOut: configuration.transferOut,
                                                                                            transferOutUnit: configuration.transferOutUnit,
                                                                                            region: configuration.region,
                                                                                            cloudType: configuration.cloudType,
                                                                                            description: configuration.description,
                                                                                            features: configuration.features,
                                                                                            accessTypes: value,
                                                                                            certifications: configuration.certifications,
                                                                                            additionalRequirements: configuration.additionalRequirements
                                                                                          };
                                                                                  }));
                                                                    }), value);
                                                              persistDraft([
                                                                    CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                            return {
                                                                                    key: configuration.key,
                                                                                    startDate: configuration.startDate,
                                                                                    contractLength: configuration.contractLength,
                                                                                    rawStorageCapacity: configuration.rawStorageCapacity,
                                                                                    diskType: configuration.diskType,
                                                                                    connectivityMethod: configuration.connectivityMethod,
                                                                                    getRequests: configuration.getRequests,
                                                                                    getRequestsUnit: configuration.getRequestsUnit,
                                                                                    putRequests: configuration.putRequests,
                                                                                    putRequestsUnit: configuration.putRequestsUnit,
                                                                                    transferOut: configuration.transferOut,
                                                                                    transferOutUnit: configuration.transferOutUnit,
                                                                                    region: configuration.region,
                                                                                    cloudType: configuration.cloudType,
                                                                                    description: configuration.description,
                                                                                    features: configuration.features,
                                                                                    accessTypes: value,
                                                                                    certifications: configuration.certifications,
                                                                                    additionalRequirements: configuration.additionalRequirements
                                                                                  };
                                                                          })),
                                                                    context
                                                                  ]);
                                                            }),
                                                          onBlur: (function (param) {
                                                              form.blurConfigurationAccessTypes(configurationIndex);
                                                            }),
                                                          children: accessType
                                                        }, domId$3);
                                            })
                                        }) : null;
                                  var visibility$3 = state.certificationsVisibility[configurationIndex];
                                  var tmp$3;
                                  tmp$3 = visibility$3 === "Shown" ? JsxRuntime.jsx(ProjectWizardPanel.ZebraGrid.make, {
                                          items: options.certifications,
                                          mobile: mobile,
                                          children: (function (certification) {
                                              var domId$3 = domId("certification-" + certification, configurationIndex);
                                              return JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                                          id: domId$3,
                                                          size: "MD",
                                                          checked: Belt_SetString.has(configuration.certifications, certification),
                                                          containerClassName: css.checkbox,
                                                          onChange: (function ($$event) {
                                                              var value = ReactForm.checked($$event) ? Belt_SetString.add(configuration.certifications, certification) : Belt_SetString.remove(configuration.certifications, certification);
                                                              form.updateConfigurationCertifications(configurationIndex, (function (input, value) {
                                                                      return CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                    return {
                                                                                            key: configuration.key,
                                                                                            startDate: configuration.startDate,
                                                                                            contractLength: configuration.contractLength,
                                                                                            rawStorageCapacity: configuration.rawStorageCapacity,
                                                                                            diskType: configuration.diskType,
                                                                                            connectivityMethod: configuration.connectivityMethod,
                                                                                            getRequests: configuration.getRequests,
                                                                                            getRequestsUnit: configuration.getRequestsUnit,
                                                                                            putRequests: configuration.putRequests,
                                                                                            putRequestsUnit: configuration.putRequestsUnit,
                                                                                            transferOut: configuration.transferOut,
                                                                                            transferOutUnit: configuration.transferOutUnit,
                                                                                            region: configuration.region,
                                                                                            cloudType: configuration.cloudType,
                                                                                            description: configuration.description,
                                                                                            features: configuration.features,
                                                                                            accessTypes: configuration.accessTypes,
                                                                                            certifications: value,
                                                                                            additionalRequirements: configuration.additionalRequirements
                                                                                          };
                                                                                  }));
                                                                    }), value);
                                                              persistDraft([
                                                                    CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                            return {
                                                                                    key: configuration.key,
                                                                                    startDate: configuration.startDate,
                                                                                    contractLength: configuration.contractLength,
                                                                                    rawStorageCapacity: configuration.rawStorageCapacity,
                                                                                    diskType: configuration.diskType,
                                                                                    connectivityMethod: configuration.connectivityMethod,
                                                                                    getRequests: configuration.getRequests,
                                                                                    getRequestsUnit: configuration.getRequestsUnit,
                                                                                    putRequests: configuration.putRequests,
                                                                                    putRequestsUnit: configuration.putRequestsUnit,
                                                                                    transferOut: configuration.transferOut,
                                                                                    transferOutUnit: configuration.transferOutUnit,
                                                                                    region: configuration.region,
                                                                                    cloudType: configuration.cloudType,
                                                                                    description: configuration.description,
                                                                                    features: configuration.features,
                                                                                    accessTypes: configuration.accessTypes,
                                                                                    certifications: value,
                                                                                    additionalRequirements: configuration.additionalRequirements
                                                                                  };
                                                                          })),
                                                                    context
                                                                  ]);
                                                            }),
                                                          onBlur: (function (param) {
                                                              form.blurConfigurationCertifications(configurationIndex);
                                                            }),
                                                          children: certification
                                                        }, domId$3);
                                            })
                                        }) : null;
                                  var visibility$4 = state.additionalRequirementsVisibility[configurationIndex];
                                  var tmp$4;
                                  if (visibility$4 === "Shown") {
                                    var domId$3 = domId("additional-requirements", configurationIndex);
                                    tmp$4 = JsxRuntime.jsx(Textarea.make, {
                                          id: domId$3,
                                          value: Belt_Option.getWithDefault(configuration.additionalRequirements, ""),
                                          rows: 5,
                                          wrapperClassName: css.textarea,
                                          onBlur: (function (param) {
                                              form.blurConfigurationAdditionalRequirements(configurationIndex);
                                            }),
                                          onChange: (function ($$event) {
                                              var value = ReactForm.value($$event);
                                              form.updateConfigurationAdditionalRequirements(configurationIndex, (function (input, value) {
                                                      return CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                    return {
                                                                            key: configuration.key,
                                                                            startDate: configuration.startDate,
                                                                            contractLength: configuration.contractLength,
                                                                            rawStorageCapacity: configuration.rawStorageCapacity,
                                                                            diskType: configuration.diskType,
                                                                            connectivityMethod: configuration.connectivityMethod,
                                                                            getRequests: configuration.getRequests,
                                                                            getRequestsUnit: configuration.getRequestsUnit,
                                                                            putRequests: configuration.putRequests,
                                                                            putRequestsUnit: configuration.putRequestsUnit,
                                                                            transferOut: configuration.transferOut,
                                                                            transferOutUnit: configuration.transferOutUnit,
                                                                            region: configuration.region,
                                                                            cloudType: configuration.cloudType,
                                                                            description: configuration.description,
                                                                            features: configuration.features,
                                                                            accessTypes: configuration.accessTypes,
                                                                            certifications: configuration.certifications,
                                                                            additionalRequirements: value
                                                                          };
                                                                  }));
                                                    }), value);
                                              persistDraft([
                                                    CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                            return {
                                                                    key: configuration.key,
                                                                    startDate: configuration.startDate,
                                                                    contractLength: configuration.contractLength,
                                                                    rawStorageCapacity: configuration.rawStorageCapacity,
                                                                    diskType: configuration.diskType,
                                                                    connectivityMethod: configuration.connectivityMethod,
                                                                    getRequests: configuration.getRequests,
                                                                    getRequestsUnit: configuration.getRequestsUnit,
                                                                    putRequests: configuration.putRequests,
                                                                    putRequestsUnit: configuration.putRequestsUnit,
                                                                    transferOut: configuration.transferOut,
                                                                    transferOutUnit: configuration.transferOutUnit,
                                                                    region: configuration.region,
                                                                    cloudType: configuration.cloudType,
                                                                    description: configuration.description,
                                                                    features: configuration.features,
                                                                    accessTypes: configuration.accessTypes,
                                                                    certifications: configuration.certifications,
                                                                    additionalRequirements: value
                                                                  };
                                                          })),
                                                    context
                                                  ]);
                                            })
                                        });
                                  } else {
                                    tmp$4 = null;
                                  }
                                  tmp = JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx(Label.make, {
                                                        forId: domId$1,
                                                        status: result,
                                                        className: css.required,
                                                        children: "Service Start date"
                                                      }),
                                                  JsxRuntime.jsx(DatePicker.make, {
                                                        id: domId$1,
                                                        value: Belt_Option.map(configuration.startDate, (function (prim) {
                                                                return prim;
                                                              })),
                                                        fromMonth: Caml_option.some(AddDays(new Date(), 1)),
                                                        disabledDays: [{
                                                            from: new Date(0, 0),
                                                            to: new Date()
                                                          }],
                                                        status: Belt_Option.map(result, (function (x) {
                                                                if (x.TAG === "Ok") {
                                                                  return "Valid";
                                                                } else {
                                                                  return "Error";
                                                                }
                                                              })),
                                                        onDayChange: (function (date, param, param$1) {
                                                            form.updateConfigurationStartDate(configurationIndex, (function (input, value) {
                                                                    return CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                  return {
                                                                                          key: configuration.key,
                                                                                          startDate: value,
                                                                                          contractLength: configuration.contractLength,
                                                                                          rawStorageCapacity: configuration.rawStorageCapacity,
                                                                                          diskType: configuration.diskType,
                                                                                          connectivityMethod: configuration.connectivityMethod,
                                                                                          getRequests: configuration.getRequests,
                                                                                          getRequestsUnit: configuration.getRequestsUnit,
                                                                                          putRequests: configuration.putRequests,
                                                                                          putRequestsUnit: configuration.putRequestsUnit,
                                                                                          transferOut: configuration.transferOut,
                                                                                          transferOutUnit: configuration.transferOutUnit,
                                                                                          region: configuration.region,
                                                                                          cloudType: configuration.cloudType,
                                                                                          description: configuration.description,
                                                                                          features: configuration.features,
                                                                                          accessTypes: configuration.accessTypes,
                                                                                          certifications: configuration.certifications,
                                                                                          additionalRequirements: configuration.additionalRequirements
                                                                                        };
                                                                                }));
                                                                  }), Caml_option.some(date));
                                                            persistDraft([
                                                                  CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                          return {
                                                                                  key: configuration.key,
                                                                                  startDate: Caml_option.some(date),
                                                                                  contractLength: configuration.contractLength,
                                                                                  rawStorageCapacity: configuration.rawStorageCapacity,
                                                                                  diskType: configuration.diskType,
                                                                                  connectivityMethod: configuration.connectivityMethod,
                                                                                  getRequests: configuration.getRequests,
                                                                                  getRequestsUnit: configuration.getRequestsUnit,
                                                                                  putRequests: configuration.putRequests,
                                                                                  putRequestsUnit: configuration.putRequestsUnit,
                                                                                  transferOut: configuration.transferOut,
                                                                                  transferOutUnit: configuration.transferOutUnit,
                                                                                  region: configuration.region,
                                                                                  cloudType: configuration.cloudType,
                                                                                  description: configuration.description,
                                                                                  features: configuration.features,
                                                                                  accessTypes: configuration.accessTypes,
                                                                                  certifications: configuration.certifications,
                                                                                  additionalRequirements: configuration.additionalRequirements
                                                                                };
                                                                        })),
                                                                  context
                                                                ]);
                                                          }),
                                                        className: css.startDate
                                                      }),
                                                  JsxRuntime.jsx(ProjectWizard.ValidationMessage.make, {
                                                        status: result
                                                      })
                                                ],
                                                className: css.field
                                              }),
                                          JsxRuntime.jsx(CreateCloudProjectIaasCloudStorageConfiguration__DropdownField.make, {
                                                domId: domId("contract-term", configurationIndex),
                                                label: "Contract Term",
                                                value: contractLength !== undefined ? String(contractLength.value) : "",
                                                onChange: (function ($$event) {
                                                    var value = Belt_Array.getBy(options.contractLengths, (function (cl) {
                                                            return Belt_Option.mapWithDefault(Belt_Int.fromString(ReactForm.value($$event)), false, (function (value) {
                                                                          return value === cl.value;
                                                                        }));
                                                          }));
                                                    form.updateConfigurationContractLength(configurationIndex, (function (input, value) {
                                                            return CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                          return {
                                                                                  key: configuration.key,
                                                                                  startDate: configuration.startDate,
                                                                                  contractLength: value,
                                                                                  rawStorageCapacity: configuration.rawStorageCapacity,
                                                                                  diskType: configuration.diskType,
                                                                                  connectivityMethod: configuration.connectivityMethod,
                                                                                  getRequests: configuration.getRequests,
                                                                                  getRequestsUnit: configuration.getRequestsUnit,
                                                                                  putRequests: configuration.putRequests,
                                                                                  putRequestsUnit: configuration.putRequestsUnit,
                                                                                  transferOut: configuration.transferOut,
                                                                                  transferOutUnit: configuration.transferOutUnit,
                                                                                  region: configuration.region,
                                                                                  cloudType: configuration.cloudType,
                                                                                  description: configuration.description,
                                                                                  features: configuration.features,
                                                                                  accessTypes: configuration.accessTypes,
                                                                                  certifications: configuration.certifications,
                                                                                  additionalRequirements: configuration.additionalRequirements
                                                                                };
                                                                        }));
                                                          }), value);
                                                    persistDraft([
                                                          CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                  return {
                                                                          key: configuration.key,
                                                                          startDate: configuration.startDate,
                                                                          contractLength: value,
                                                                          rawStorageCapacity: configuration.rawStorageCapacity,
                                                                          diskType: configuration.diskType,
                                                                          connectivityMethod: configuration.connectivityMethod,
                                                                          getRequests: configuration.getRequests,
                                                                          getRequestsUnit: configuration.getRequestsUnit,
                                                                          putRequests: configuration.putRequests,
                                                                          putRequestsUnit: configuration.putRequestsUnit,
                                                                          transferOut: configuration.transferOut,
                                                                          transferOutUnit: configuration.transferOutUnit,
                                                                          region: configuration.region,
                                                                          cloudType: configuration.cloudType,
                                                                          description: configuration.description,
                                                                          features: configuration.features,
                                                                          accessTypes: configuration.accessTypes,
                                                                          certifications: configuration.certifications,
                                                                          additionalRequirements: configuration.additionalRequirements
                                                                        };
                                                                })),
                                                          context
                                                        ]);
                                                  }),
                                                isRequired: true,
                                                className: css.limitedWidthSelectField,
                                                status: form.configurationContractLengthResult(configurationIndex),
                                                onBlur: (function (param) {
                                                    form.blurConfigurationContractLength(configurationIndex);
                                                  }),
                                                children: Belt_Array.map(options.contractLengths, (function (term) {
                                                        return JsxRuntime.jsx(Select.$$Option.make, {
                                                                    value: String(term.value),
                                                                    children: term.label
                                                                  }, term.label);
                                                      }))
                                              }),
                                          JsxRuntime.jsx("div", {
                                                className: css.horizontalLine
                                              }),
                                          JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx("h4", {
                                                        children: "Cloud Storage",
                                                        className: css.formSectionHeading
                                                      }),
                                                  JsxRuntime.jsxs("div", {
                                                        children: [
                                                          JsxRuntime.jsx(CreateCloudProjectIaasCloudStorageConfiguration__DropdownField.make, {
                                                                domId: domId("raw-storage-capacity", configurationIndex),
                                                                label: "Storage Capacity",
                                                                value: configuration.rawStorageCapacity,
                                                                onChange: (function ($$event) {
                                                                    var value = Belt_Option.getWithDefault(Belt_Array.getBy(options.rawStorageCapacities, (function (x) {
                                                                                return ReactForm.value($$event) === x;
                                                                              })), "");
                                                                    form.updateConfigurationRawStorageCapacity(configurationIndex, (function (input, value) {
                                                                            return CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                          return {
                                                                                                  key: configuration.key,
                                                                                                  startDate: configuration.startDate,
                                                                                                  contractLength: configuration.contractLength,
                                                                                                  rawStorageCapacity: value,
                                                                                                  diskType: configuration.diskType,
                                                                                                  connectivityMethod: configuration.connectivityMethod,
                                                                                                  getRequests: configuration.getRequests,
                                                                                                  getRequestsUnit: configuration.getRequestsUnit,
                                                                                                  putRequests: configuration.putRequests,
                                                                                                  putRequestsUnit: configuration.putRequestsUnit,
                                                                                                  transferOut: configuration.transferOut,
                                                                                                  transferOutUnit: configuration.transferOutUnit,
                                                                                                  region: configuration.region,
                                                                                                  cloudType: configuration.cloudType,
                                                                                                  description: configuration.description,
                                                                                                  features: configuration.features,
                                                                                                  accessTypes: configuration.accessTypes,
                                                                                                  certifications: configuration.certifications,
                                                                                                  additionalRequirements: configuration.additionalRequirements
                                                                                                };
                                                                                        }));
                                                                          }), value);
                                                                    persistDraft([
                                                                          CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                  return {
                                                                                          key: configuration.key,
                                                                                          startDate: configuration.startDate,
                                                                                          contractLength: configuration.contractLength,
                                                                                          rawStorageCapacity: value,
                                                                                          diskType: configuration.diskType,
                                                                                          connectivityMethod: configuration.connectivityMethod,
                                                                                          getRequests: configuration.getRequests,
                                                                                          getRequestsUnit: configuration.getRequestsUnit,
                                                                                          putRequests: configuration.putRequests,
                                                                                          putRequestsUnit: configuration.putRequestsUnit,
                                                                                          transferOut: configuration.transferOut,
                                                                                          transferOutUnit: configuration.transferOutUnit,
                                                                                          region: configuration.region,
                                                                                          cloudType: configuration.cloudType,
                                                                                          description: configuration.description,
                                                                                          features: configuration.features,
                                                                                          accessTypes: configuration.accessTypes,
                                                                                          certifications: configuration.certifications,
                                                                                          additionalRequirements: configuration.additionalRequirements
                                                                                        };
                                                                                })),
                                                                          context
                                                                        ]);
                                                                  }),
                                                                isRequired: true,
                                                                className: css.limitedWidthSelectField,
                                                                status: form.configurationRawStorageCapacityResult(configurationIndex),
                                                                onBlur: (function (param) {
                                                                    form.blurConfigurationRawStorageCapacity(configurationIndex);
                                                                  }),
                                                                children: Belt_Array.map(options.rawStorageCapacities, (function (capacity) {
                                                                        return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                    value: capacity,
                                                                                    children: capacity
                                                                                  }, capacity);
                                                                      })),
                                                                wrapperClassName: css.rawStorageCapacityFieldWrapper
                                                              }),
                                                          JsxRuntime.jsx(CreateCloudProjectIaasCloudStorageConfiguration__DropdownField.make, {
                                                                domId: domId("disk-type", configurationIndex),
                                                                label: "Disk Type",
                                                                value: Belt_Option.getWithDefault(configuration.diskType, ""),
                                                                onChange: (function ($$event) {
                                                                    var value = ReactForm.value($$event);
                                                                    form.updateConfigurationDiskType(configurationIndex, (function (input, value) {
                                                                            return CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                          return {
                                                                                                  key: configuration.key,
                                                                                                  startDate: configuration.startDate,
                                                                                                  contractLength: configuration.contractLength,
                                                                                                  rawStorageCapacity: configuration.rawStorageCapacity,
                                                                                                  diskType: value,
                                                                                                  connectivityMethod: configuration.connectivityMethod,
                                                                                                  getRequests: configuration.getRequests,
                                                                                                  getRequestsUnit: configuration.getRequestsUnit,
                                                                                                  putRequests: configuration.putRequests,
                                                                                                  putRequestsUnit: configuration.putRequestsUnit,
                                                                                                  transferOut: configuration.transferOut,
                                                                                                  transferOutUnit: configuration.transferOutUnit,
                                                                                                  region: configuration.region,
                                                                                                  cloudType: configuration.cloudType,
                                                                                                  description: configuration.description,
                                                                                                  features: configuration.features,
                                                                                                  accessTypes: configuration.accessTypes,
                                                                                                  certifications: configuration.certifications,
                                                                                                  additionalRequirements: configuration.additionalRequirements
                                                                                                };
                                                                                        }));
                                                                          }), value);
                                                                    persistDraft([
                                                                          CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                  return {
                                                                                          key: configuration.key,
                                                                                          startDate: configuration.startDate,
                                                                                          contractLength: configuration.contractLength,
                                                                                          rawStorageCapacity: configuration.rawStorageCapacity,
                                                                                          diskType: value,
                                                                                          connectivityMethod: configuration.connectivityMethod,
                                                                                          getRequests: configuration.getRequests,
                                                                                          getRequestsUnit: configuration.getRequestsUnit,
                                                                                          putRequests: configuration.putRequests,
                                                                                          putRequestsUnit: configuration.putRequestsUnit,
                                                                                          transferOut: configuration.transferOut,
                                                                                          transferOutUnit: configuration.transferOutUnit,
                                                                                          region: configuration.region,
                                                                                          cloudType: configuration.cloudType,
                                                                                          description: configuration.description,
                                                                                          features: configuration.features,
                                                                                          accessTypes: configuration.accessTypes,
                                                                                          certifications: configuration.certifications,
                                                                                          additionalRequirements: configuration.additionalRequirements
                                                                                        };
                                                                                })),
                                                                          context
                                                                        ]);
                                                                  }),
                                                                isRequired: false,
                                                                className: css.limitedWidthSelectField,
                                                                onBlur: (function (param) {
                                                                    form.blurConfigurationDiskType(configurationIndex);
                                                                  }),
                                                                children: Belt_Array.map(options.diskTypes, (function (disk) {
                                                                        return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                    value: disk,
                                                                                    children: disk
                                                                                  }, disk);
                                                                      }))
                                                              }),
                                                          JsxRuntime.jsx(CreateCloudProjectIaasCloudStorageConfiguration__DropdownField.make, {
                                                                domId: domId("connectivity-method", configurationIndex),
                                                                label: "Connectivity Method",
                                                                value: Belt_Option.getWithDefault(configuration.connectivityMethod, ""),
                                                                onChange: (function ($$event) {
                                                                    var value = ReactForm.value($$event);
                                                                    form.updateConfigurationConnectivityMethod(configurationIndex, (function (input, value) {
                                                                            return CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                          return {
                                                                                                  key: configuration.key,
                                                                                                  startDate: configuration.startDate,
                                                                                                  contractLength: configuration.contractLength,
                                                                                                  rawStorageCapacity: configuration.rawStorageCapacity,
                                                                                                  diskType: configuration.diskType,
                                                                                                  connectivityMethod: value,
                                                                                                  getRequests: configuration.getRequests,
                                                                                                  getRequestsUnit: configuration.getRequestsUnit,
                                                                                                  putRequests: configuration.putRequests,
                                                                                                  putRequestsUnit: configuration.putRequestsUnit,
                                                                                                  transferOut: configuration.transferOut,
                                                                                                  transferOutUnit: configuration.transferOutUnit,
                                                                                                  region: configuration.region,
                                                                                                  cloudType: configuration.cloudType,
                                                                                                  description: configuration.description,
                                                                                                  features: configuration.features,
                                                                                                  accessTypes: configuration.accessTypes,
                                                                                                  certifications: configuration.certifications,
                                                                                                  additionalRequirements: configuration.additionalRequirements
                                                                                                };
                                                                                        }));
                                                                          }), value);
                                                                    persistDraft([
                                                                          CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                  return {
                                                                                          key: configuration.key,
                                                                                          startDate: configuration.startDate,
                                                                                          contractLength: configuration.contractLength,
                                                                                          rawStorageCapacity: configuration.rawStorageCapacity,
                                                                                          diskType: configuration.diskType,
                                                                                          connectivityMethod: value,
                                                                                          getRequests: configuration.getRequests,
                                                                                          getRequestsUnit: configuration.getRequestsUnit,
                                                                                          putRequests: configuration.putRequests,
                                                                                          putRequestsUnit: configuration.putRequestsUnit,
                                                                                          transferOut: configuration.transferOut,
                                                                                          transferOutUnit: configuration.transferOutUnit,
                                                                                          region: configuration.region,
                                                                                          cloudType: configuration.cloudType,
                                                                                          description: configuration.description,
                                                                                          features: configuration.features,
                                                                                          accessTypes: configuration.accessTypes,
                                                                                          certifications: configuration.certifications,
                                                                                          additionalRequirements: configuration.additionalRequirements
                                                                                        };
                                                                                })),
                                                                          context
                                                                        ]);
                                                                  }),
                                                                isRequired: false,
                                                                className: css.connectivityMethod,
                                                                onBlur: (function (param) {
                                                                    form.blurConfigurationConnectivityMethod(configurationIndex);
                                                                  }),
                                                                children: Belt_Array.map(options.connectivityMethods, (function (connectivityMethod) {
                                                                        return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                    value: connectivityMethod,
                                                                                    children: connectivityMethod
                                                                                  }, connectivityMethod);
                                                                      }))
                                                              })
                                                        ],
                                                        className: css.fieldRow
                                                      }),
                                                  JsxRuntime.jsxs("div", {
                                                        children: [
                                                          JsxRuntime.jsx(CreateCloudProjectIaasCloudStorageConfiguration__InputWithUnitSelector.make, {
                                                                domId: domId("transfer-out", configurationIndex),
                                                                label: "Transfer Out",
                                                                value: Belt_Option.getWithDefault(Belt_Option.map(configuration.transferOut, (function (prim) {
                                                                            return String(prim);
                                                                          })), ""),
                                                                unit: configuration.transferOutUnit,
                                                                onInputChange: (function ($$event) {
                                                                    var x = ReactForm.value($$event);
                                                                    var value;
                                                                    if (x === "") {
                                                                      value = {
                                                                        TAG: "Ok",
                                                                        _0: undefined
                                                                      };
                                                                    } else {
                                                                      var n = Belt_Int.fromString(x);
                                                                      value = n !== undefined && String(n) === x ? ({
                                                                            TAG: "Ok",
                                                                            _0: n
                                                                          }) : ({
                                                                            TAG: "Error",
                                                                            _0: undefined
                                                                          });
                                                                    }
                                                                    if (value.TAG !== "Ok") {
                                                                      return ;
                                                                    }
                                                                    var value$1 = value._0;
                                                                    form.updateConfigurationTransferOut(configurationIndex, (function (input, value) {
                                                                            return CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                          return {
                                                                                                  key: configuration.key,
                                                                                                  startDate: configuration.startDate,
                                                                                                  contractLength: configuration.contractLength,
                                                                                                  rawStorageCapacity: configuration.rawStorageCapacity,
                                                                                                  diskType: configuration.diskType,
                                                                                                  connectivityMethod: configuration.connectivityMethod,
                                                                                                  getRequests: configuration.getRequests,
                                                                                                  getRequestsUnit: configuration.getRequestsUnit,
                                                                                                  putRequests: configuration.putRequests,
                                                                                                  putRequestsUnit: configuration.putRequestsUnit,
                                                                                                  transferOut: value,
                                                                                                  transferOutUnit: configuration.transferOutUnit,
                                                                                                  region: configuration.region,
                                                                                                  cloudType: configuration.cloudType,
                                                                                                  description: configuration.description,
                                                                                                  features: configuration.features,
                                                                                                  accessTypes: configuration.accessTypes,
                                                                                                  certifications: configuration.certifications,
                                                                                                  additionalRequirements: configuration.additionalRequirements
                                                                                                };
                                                                                        }));
                                                                          }), value$1);
                                                                    persistDraft([
                                                                          CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                  return {
                                                                                          key: configuration.key,
                                                                                          startDate: configuration.startDate,
                                                                                          contractLength: configuration.contractLength,
                                                                                          rawStorageCapacity: configuration.rawStorageCapacity,
                                                                                          diskType: configuration.diskType,
                                                                                          connectivityMethod: configuration.connectivityMethod,
                                                                                          getRequests: configuration.getRequests,
                                                                                          getRequestsUnit: configuration.getRequestsUnit,
                                                                                          putRequests: configuration.putRequests,
                                                                                          putRequestsUnit: configuration.putRequestsUnit,
                                                                                          transferOut: value$1,
                                                                                          transferOutUnit: configuration.transferOutUnit,
                                                                                          region: configuration.region,
                                                                                          cloudType: configuration.cloudType,
                                                                                          description: configuration.description,
                                                                                          features: configuration.features,
                                                                                          accessTypes: configuration.accessTypes,
                                                                                          certifications: configuration.certifications,
                                                                                          additionalRequirements: configuration.additionalRequirements
                                                                                        };
                                                                                })),
                                                                          context
                                                                        ]);
                                                                  }),
                                                                onInputBlur: (function (param) {
                                                                    form.blurConfigurationTransferOut(configurationIndex);
                                                                  }),
                                                                inputStatus: form.configurationTransferOutResult(configurationIndex),
                                                                onUnitChange: (function ($$event) {
                                                                    var selectedUnit = ReactForm.value($$event);
                                                                    var value = Belt_Option.getWithDefault((function (__x) {
                                                                              return Js_array.find((function (unit) {
                                                                                            return unit === selectedUnit;
                                                                                          }), __x);
                                                                            })(options.transferOutUnits), "");
                                                                    form.updateConfigurationTransferOutUnit(configurationIndex, (function (input, value) {
                                                                            return CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                          return {
                                                                                                  key: configuration.key,
                                                                                                  startDate: configuration.startDate,
                                                                                                  contractLength: configuration.contractLength,
                                                                                                  rawStorageCapacity: configuration.rawStorageCapacity,
                                                                                                  diskType: configuration.diskType,
                                                                                                  connectivityMethod: configuration.connectivityMethod,
                                                                                                  getRequests: configuration.getRequests,
                                                                                                  getRequestsUnit: configuration.getRequestsUnit,
                                                                                                  putRequests: configuration.putRequests,
                                                                                                  putRequestsUnit: configuration.putRequestsUnit,
                                                                                                  transferOut: configuration.transferOut,
                                                                                                  transferOutUnit: value,
                                                                                                  region: configuration.region,
                                                                                                  cloudType: configuration.cloudType,
                                                                                                  description: configuration.description,
                                                                                                  features: configuration.features,
                                                                                                  accessTypes: configuration.accessTypes,
                                                                                                  certifications: configuration.certifications,
                                                                                                  additionalRequirements: configuration.additionalRequirements
                                                                                                };
                                                                                        }));
                                                                          }), value);
                                                                    persistDraft([
                                                                          CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                  return {
                                                                                          key: configuration.key,
                                                                                          startDate: configuration.startDate,
                                                                                          contractLength: configuration.contractLength,
                                                                                          rawStorageCapacity: configuration.rawStorageCapacity,
                                                                                          diskType: configuration.diskType,
                                                                                          connectivityMethod: configuration.connectivityMethod,
                                                                                          getRequests: configuration.getRequests,
                                                                                          getRequestsUnit: configuration.getRequestsUnit,
                                                                                          putRequests: configuration.putRequests,
                                                                                          putRequestsUnit: configuration.putRequestsUnit,
                                                                                          transferOut: configuration.transferOut,
                                                                                          transferOutUnit: value,
                                                                                          region: configuration.region,
                                                                                          cloudType: configuration.cloudType,
                                                                                          description: configuration.description,
                                                                                          features: configuration.features,
                                                                                          accessTypes: configuration.accessTypes,
                                                                                          certifications: configuration.certifications,
                                                                                          additionalRequirements: configuration.additionalRequirements
                                                                                        };
                                                                                })),
                                                                          context
                                                                        ]);
                                                                  }),
                                                                onUnitBlur: (function (param) {
                                                                    form.blurConfigurationTransferOutUnit(configurationIndex);
                                                                  }),
                                                                unitStatus: form.configurationTransferOutUnitResult(configurationIndex),
                                                                unitOptions: options.transferOutUnits
                                                              }),
                                                          JsxRuntime.jsx(CreateCloudProjectIaasCloudStorageConfiguration__InputWithUnitSelector.make, {
                                                                domId: domId("get-requests", configurationIndex),
                                                                label: "Get Requests",
                                                                value: Belt_Option.getWithDefault(Belt_Option.map(configuration.getRequests, (function (prim) {
                                                                            return String(prim);
                                                                          })), ""),
                                                                unit: configuration.getRequestsUnit,
                                                                onInputChange: (function ($$event) {
                                                                    var x = ReactForm.value($$event);
                                                                    var value;
                                                                    if (x === "") {
                                                                      value = {
                                                                        TAG: "Ok",
                                                                        _0: undefined
                                                                      };
                                                                    } else {
                                                                      var n = Belt_Int.fromString(x);
                                                                      value = n !== undefined && String(n) === x ? ({
                                                                            TAG: "Ok",
                                                                            _0: n
                                                                          }) : ({
                                                                            TAG: "Error",
                                                                            _0: undefined
                                                                          });
                                                                    }
                                                                    if (value.TAG !== "Ok") {
                                                                      return ;
                                                                    }
                                                                    var value$1 = value._0;
                                                                    form.updateConfigurationGetRequests(configurationIndex, (function (input, value) {
                                                                            return CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                          return {
                                                                                                  key: configuration.key,
                                                                                                  startDate: configuration.startDate,
                                                                                                  contractLength: configuration.contractLength,
                                                                                                  rawStorageCapacity: configuration.rawStorageCapacity,
                                                                                                  diskType: configuration.diskType,
                                                                                                  connectivityMethod: configuration.connectivityMethod,
                                                                                                  getRequests: value,
                                                                                                  getRequestsUnit: configuration.getRequestsUnit,
                                                                                                  putRequests: configuration.putRequests,
                                                                                                  putRequestsUnit: configuration.putRequestsUnit,
                                                                                                  transferOut: configuration.transferOut,
                                                                                                  transferOutUnit: configuration.transferOutUnit,
                                                                                                  region: configuration.region,
                                                                                                  cloudType: configuration.cloudType,
                                                                                                  description: configuration.description,
                                                                                                  features: configuration.features,
                                                                                                  accessTypes: configuration.accessTypes,
                                                                                                  certifications: configuration.certifications,
                                                                                                  additionalRequirements: configuration.additionalRequirements
                                                                                                };
                                                                                        }));
                                                                          }), value$1);
                                                                    persistDraft([
                                                                          CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                  return {
                                                                                          key: configuration.key,
                                                                                          startDate: configuration.startDate,
                                                                                          contractLength: configuration.contractLength,
                                                                                          rawStorageCapacity: configuration.rawStorageCapacity,
                                                                                          diskType: configuration.diskType,
                                                                                          connectivityMethod: configuration.connectivityMethod,
                                                                                          getRequests: value$1,
                                                                                          getRequestsUnit: configuration.getRequestsUnit,
                                                                                          putRequests: configuration.putRequests,
                                                                                          putRequestsUnit: configuration.putRequestsUnit,
                                                                                          transferOut: configuration.transferOut,
                                                                                          transferOutUnit: configuration.transferOutUnit,
                                                                                          region: configuration.region,
                                                                                          cloudType: configuration.cloudType,
                                                                                          description: configuration.description,
                                                                                          features: configuration.features,
                                                                                          accessTypes: configuration.accessTypes,
                                                                                          certifications: configuration.certifications,
                                                                                          additionalRequirements: configuration.additionalRequirements
                                                                                        };
                                                                                })),
                                                                          context
                                                                        ]);
                                                                  }),
                                                                onInputBlur: (function (param) {
                                                                    form.blurConfigurationGetRequests(configurationIndex);
                                                                  }),
                                                                inputStatus: form.configurationGetRequestsResult(configurationIndex),
                                                                onUnitChange: (function ($$event) {
                                                                    var selectedUnit = ReactForm.value($$event);
                                                                    var value = Belt_Option.getWithDefault((function (__x) {
                                                                              return Js_array.find((function (unit) {
                                                                                            return unit === selectedUnit;
                                                                                          }), __x);
                                                                            })(options.getRequestsUnits), "");
                                                                    form.updateConfigurationGetRequestsUnit(configurationIndex, (function (input, value) {
                                                                            return CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                          return {
                                                                                                  key: configuration.key,
                                                                                                  startDate: configuration.startDate,
                                                                                                  contractLength: configuration.contractLength,
                                                                                                  rawStorageCapacity: configuration.rawStorageCapacity,
                                                                                                  diskType: configuration.diskType,
                                                                                                  connectivityMethod: configuration.connectivityMethod,
                                                                                                  getRequests: configuration.getRequests,
                                                                                                  getRequestsUnit: value,
                                                                                                  putRequests: configuration.putRequests,
                                                                                                  putRequestsUnit: configuration.putRequestsUnit,
                                                                                                  transferOut: configuration.transferOut,
                                                                                                  transferOutUnit: configuration.transferOutUnit,
                                                                                                  region: configuration.region,
                                                                                                  cloudType: configuration.cloudType,
                                                                                                  description: configuration.description,
                                                                                                  features: configuration.features,
                                                                                                  accessTypes: configuration.accessTypes,
                                                                                                  certifications: configuration.certifications,
                                                                                                  additionalRequirements: configuration.additionalRequirements
                                                                                                };
                                                                                        }));
                                                                          }), value);
                                                                    persistDraft([
                                                                          CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                  return {
                                                                                          key: configuration.key,
                                                                                          startDate: configuration.startDate,
                                                                                          contractLength: configuration.contractLength,
                                                                                          rawStorageCapacity: configuration.rawStorageCapacity,
                                                                                          diskType: configuration.diskType,
                                                                                          connectivityMethod: configuration.connectivityMethod,
                                                                                          getRequests: configuration.getRequests,
                                                                                          getRequestsUnit: value,
                                                                                          putRequests: configuration.putRequests,
                                                                                          putRequestsUnit: configuration.putRequestsUnit,
                                                                                          transferOut: configuration.transferOut,
                                                                                          transferOutUnit: configuration.transferOutUnit,
                                                                                          region: configuration.region,
                                                                                          cloudType: configuration.cloudType,
                                                                                          description: configuration.description,
                                                                                          features: configuration.features,
                                                                                          accessTypes: configuration.accessTypes,
                                                                                          certifications: configuration.certifications,
                                                                                          additionalRequirements: configuration.additionalRequirements
                                                                                        };
                                                                                })),
                                                                          context
                                                                        ]);
                                                                  }),
                                                                onUnitBlur: (function (param) {
                                                                    form.blurConfigurationGetRequestsUnit(configurationIndex);
                                                                  }),
                                                                unitStatus: form.configurationGetRequestsUnitResult(configurationIndex),
                                                                unitOptions: options.getRequestsUnits
                                                              }),
                                                          JsxRuntime.jsx(CreateCloudProjectIaasCloudStorageConfiguration__InputWithUnitSelector.make, {
                                                                domId: domId("put-requests", configurationIndex),
                                                                label: "Put Requests",
                                                                value: Belt_Option.getWithDefault(Belt_Option.map(configuration.putRequests, (function (prim) {
                                                                            return String(prim);
                                                                          })), ""),
                                                                unit: configuration.putRequestsUnit,
                                                                onInputChange: (function ($$event) {
                                                                    var x = ReactForm.value($$event);
                                                                    var value;
                                                                    if (x === "") {
                                                                      value = {
                                                                        TAG: "Ok",
                                                                        _0: undefined
                                                                      };
                                                                    } else {
                                                                      var n = Belt_Int.fromString(x);
                                                                      value = n !== undefined && String(n) === x ? ({
                                                                            TAG: "Ok",
                                                                            _0: n
                                                                          }) : ({
                                                                            TAG: "Error",
                                                                            _0: undefined
                                                                          });
                                                                    }
                                                                    if (value.TAG !== "Ok") {
                                                                      return ;
                                                                    }
                                                                    var value$1 = value._0;
                                                                    form.updateConfigurationPutRequests(configurationIndex, (function (input, value) {
                                                                            return CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                          return {
                                                                                                  key: configuration.key,
                                                                                                  startDate: configuration.startDate,
                                                                                                  contractLength: configuration.contractLength,
                                                                                                  rawStorageCapacity: configuration.rawStorageCapacity,
                                                                                                  diskType: configuration.diskType,
                                                                                                  connectivityMethod: configuration.connectivityMethod,
                                                                                                  getRequests: configuration.getRequests,
                                                                                                  getRequestsUnit: configuration.getRequestsUnit,
                                                                                                  putRequests: value,
                                                                                                  putRequestsUnit: configuration.putRequestsUnit,
                                                                                                  transferOut: configuration.transferOut,
                                                                                                  transferOutUnit: configuration.transferOutUnit,
                                                                                                  region: configuration.region,
                                                                                                  cloudType: configuration.cloudType,
                                                                                                  description: configuration.description,
                                                                                                  features: configuration.features,
                                                                                                  accessTypes: configuration.accessTypes,
                                                                                                  certifications: configuration.certifications,
                                                                                                  additionalRequirements: configuration.additionalRequirements
                                                                                                };
                                                                                        }));
                                                                          }), value$1);
                                                                    persistDraft([
                                                                          CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                  return {
                                                                                          key: configuration.key,
                                                                                          startDate: configuration.startDate,
                                                                                          contractLength: configuration.contractLength,
                                                                                          rawStorageCapacity: configuration.rawStorageCapacity,
                                                                                          diskType: configuration.diskType,
                                                                                          connectivityMethod: configuration.connectivityMethod,
                                                                                          getRequests: configuration.getRequests,
                                                                                          getRequestsUnit: configuration.getRequestsUnit,
                                                                                          putRequests: value$1,
                                                                                          putRequestsUnit: configuration.putRequestsUnit,
                                                                                          transferOut: configuration.transferOut,
                                                                                          transferOutUnit: configuration.transferOutUnit,
                                                                                          region: configuration.region,
                                                                                          cloudType: configuration.cloudType,
                                                                                          description: configuration.description,
                                                                                          features: configuration.features,
                                                                                          accessTypes: configuration.accessTypes,
                                                                                          certifications: configuration.certifications,
                                                                                          additionalRequirements: configuration.additionalRequirements
                                                                                        };
                                                                                })),
                                                                          context
                                                                        ]);
                                                                  }),
                                                                onInputBlur: (function (param) {
                                                                    form.blurConfigurationPutRequests(configurationIndex);
                                                                  }),
                                                                inputStatus: form.configurationPutRequestsResult(configurationIndex),
                                                                onUnitChange: (function ($$event) {
                                                                    var selectedUnit = ReactForm.value($$event);
                                                                    var value = Belt_Option.getWithDefault((function (__x) {
                                                                              return Js_array.find((function (unit) {
                                                                                            return unit === selectedUnit;
                                                                                          }), __x);
                                                                            })(options.putRequestsUnits), "");
                                                                    form.updateConfigurationPutRequestsUnit(configurationIndex, (function (input, value) {
                                                                            return CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                          return {
                                                                                                  key: configuration.key,
                                                                                                  startDate: configuration.startDate,
                                                                                                  contractLength: configuration.contractLength,
                                                                                                  rawStorageCapacity: configuration.rawStorageCapacity,
                                                                                                  diskType: configuration.diskType,
                                                                                                  connectivityMethod: configuration.connectivityMethod,
                                                                                                  getRequests: configuration.getRequests,
                                                                                                  getRequestsUnit: configuration.getRequestsUnit,
                                                                                                  putRequests: configuration.putRequests,
                                                                                                  putRequestsUnit: value,
                                                                                                  transferOut: configuration.transferOut,
                                                                                                  transferOutUnit: configuration.transferOutUnit,
                                                                                                  region: configuration.region,
                                                                                                  cloudType: configuration.cloudType,
                                                                                                  description: configuration.description,
                                                                                                  features: configuration.features,
                                                                                                  accessTypes: configuration.accessTypes,
                                                                                                  certifications: configuration.certifications,
                                                                                                  additionalRequirements: configuration.additionalRequirements
                                                                                                };
                                                                                        }));
                                                                          }), value);
                                                                    persistDraft([
                                                                          CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                  return {
                                                                                          key: configuration.key,
                                                                                          startDate: configuration.startDate,
                                                                                          contractLength: configuration.contractLength,
                                                                                          rawStorageCapacity: configuration.rawStorageCapacity,
                                                                                          diskType: configuration.diskType,
                                                                                          connectivityMethod: configuration.connectivityMethod,
                                                                                          getRequests: configuration.getRequests,
                                                                                          getRequestsUnit: configuration.getRequestsUnit,
                                                                                          putRequests: configuration.putRequests,
                                                                                          putRequestsUnit: value,
                                                                                          transferOut: configuration.transferOut,
                                                                                          transferOutUnit: configuration.transferOutUnit,
                                                                                          region: configuration.region,
                                                                                          cloudType: configuration.cloudType,
                                                                                          description: configuration.description,
                                                                                          features: configuration.features,
                                                                                          accessTypes: configuration.accessTypes,
                                                                                          certifications: configuration.certifications,
                                                                                          additionalRequirements: configuration.additionalRequirements
                                                                                        };
                                                                                })),
                                                                          context
                                                                        ]);
                                                                  }),
                                                                onUnitBlur: (function (param) {
                                                                    form.blurConfigurationPutRequestsUnit(configurationIndex);
                                                                  }),
                                                                unitStatus: form.configurationPutRequestsUnitResult(configurationIndex),
                                                                unitOptions: options.putRequestsUnits
                                                              })
                                                        ],
                                                        className: css.fieldRow
                                                      }),
                                                  JsxRuntime.jsxs("div", {
                                                        children: [
                                                          JsxRuntime.jsx(CreateCloudProjectIaasCloudStorageConfiguration__DropdownField.make, {
                                                                domId: domId("region", configurationIndex),
                                                                label: "Region",
                                                                value: Belt_Option.getWithDefault(configuration.region, ""),
                                                                onChange: (function ($$event) {
                                                                    var value = ReactForm.value($$event);
                                                                    form.updateConfigurationRegion(configurationIndex, (function (input, value) {
                                                                            return CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                          return {
                                                                                                  key: configuration.key,
                                                                                                  startDate: configuration.startDate,
                                                                                                  contractLength: configuration.contractLength,
                                                                                                  rawStorageCapacity: configuration.rawStorageCapacity,
                                                                                                  diskType: configuration.diskType,
                                                                                                  connectivityMethod: configuration.connectivityMethod,
                                                                                                  getRequests: configuration.getRequests,
                                                                                                  getRequestsUnit: configuration.getRequestsUnit,
                                                                                                  putRequests: configuration.putRequests,
                                                                                                  putRequestsUnit: configuration.putRequestsUnit,
                                                                                                  transferOut: configuration.transferOut,
                                                                                                  transferOutUnit: configuration.transferOutUnit,
                                                                                                  region: value,
                                                                                                  cloudType: configuration.cloudType,
                                                                                                  description: configuration.description,
                                                                                                  features: configuration.features,
                                                                                                  accessTypes: configuration.accessTypes,
                                                                                                  certifications: configuration.certifications,
                                                                                                  additionalRequirements: configuration.additionalRequirements
                                                                                                };
                                                                                        }));
                                                                          }), value);
                                                                    persistDraft([
                                                                          CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                  return {
                                                                                          key: configuration.key,
                                                                                          startDate: configuration.startDate,
                                                                                          contractLength: configuration.contractLength,
                                                                                          rawStorageCapacity: configuration.rawStorageCapacity,
                                                                                          diskType: configuration.diskType,
                                                                                          connectivityMethod: configuration.connectivityMethod,
                                                                                          getRequests: configuration.getRequests,
                                                                                          getRequestsUnit: configuration.getRequestsUnit,
                                                                                          putRequests: configuration.putRequests,
                                                                                          putRequestsUnit: configuration.putRequestsUnit,
                                                                                          transferOut: configuration.transferOut,
                                                                                          transferOutUnit: configuration.transferOutUnit,
                                                                                          region: value,
                                                                                          cloudType: configuration.cloudType,
                                                                                          description: configuration.description,
                                                                                          features: configuration.features,
                                                                                          accessTypes: configuration.accessTypes,
                                                                                          certifications: configuration.certifications,
                                                                                          additionalRequirements: configuration.additionalRequirements
                                                                                        };
                                                                                })),
                                                                          context
                                                                        ]);
                                                                  }),
                                                                isRequired: false,
                                                                className: css.region,
                                                                onBlur: (function (param) {
                                                                    form.blurConfigurationRegion(configurationIndex);
                                                                  }),
                                                                children: Belt_Array.map(options.regions, (function (region) {
                                                                        return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                    value: region,
                                                                                    children: region
                                                                                  }, region);
                                                                      }))
                                                              }),
                                                          JsxRuntime.jsx(CreateCloudProjectIaasCloudStorageConfiguration__DropdownField.make, {
                                                                domId: domId("cloud-type", configurationIndex),
                                                                label: "Type",
                                                                value: Belt_Option.getWithDefault(configuration.cloudType, ""),
                                                                onChange: (function ($$event) {
                                                                    var value = ReactForm.value($$event);
                                                                    form.updateConfigurationCloudType(configurationIndex, (function (input, value) {
                                                                            return CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                          return {
                                                                                                  key: configuration.key,
                                                                                                  startDate: configuration.startDate,
                                                                                                  contractLength: configuration.contractLength,
                                                                                                  rawStorageCapacity: configuration.rawStorageCapacity,
                                                                                                  diskType: configuration.diskType,
                                                                                                  connectivityMethod: configuration.connectivityMethod,
                                                                                                  getRequests: configuration.getRequests,
                                                                                                  getRequestsUnit: configuration.getRequestsUnit,
                                                                                                  putRequests: configuration.putRequests,
                                                                                                  putRequestsUnit: configuration.putRequestsUnit,
                                                                                                  transferOut: configuration.transferOut,
                                                                                                  transferOutUnit: configuration.transferOutUnit,
                                                                                                  region: configuration.region,
                                                                                                  cloudType: value,
                                                                                                  description: configuration.description,
                                                                                                  features: configuration.features,
                                                                                                  accessTypes: configuration.accessTypes,
                                                                                                  certifications: configuration.certifications,
                                                                                                  additionalRequirements: configuration.additionalRequirements
                                                                                                };
                                                                                        }));
                                                                          }), value);
                                                                    persistDraft([
                                                                          CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                  return {
                                                                                          key: configuration.key,
                                                                                          startDate: configuration.startDate,
                                                                                          contractLength: configuration.contractLength,
                                                                                          rawStorageCapacity: configuration.rawStorageCapacity,
                                                                                          diskType: configuration.diskType,
                                                                                          connectivityMethod: configuration.connectivityMethod,
                                                                                          getRequests: configuration.getRequests,
                                                                                          getRequestsUnit: configuration.getRequestsUnit,
                                                                                          putRequests: configuration.putRequests,
                                                                                          putRequestsUnit: configuration.putRequestsUnit,
                                                                                          transferOut: configuration.transferOut,
                                                                                          transferOutUnit: configuration.transferOutUnit,
                                                                                          region: configuration.region,
                                                                                          cloudType: value,
                                                                                          description: configuration.description,
                                                                                          features: configuration.features,
                                                                                          accessTypes: configuration.accessTypes,
                                                                                          certifications: configuration.certifications,
                                                                                          additionalRequirements: configuration.additionalRequirements
                                                                                        };
                                                                                })),
                                                                          context
                                                                        ]);
                                                                  }),
                                                                isRequired: true,
                                                                className: css.cloudType,
                                                                status: form.configurationCloudTypeResult(configurationIndex),
                                                                onBlur: (function (param) {
                                                                    form.blurConfigurationCloudType(configurationIndex);
                                                                  }),
                                                                children: Belt_Array.map(options.cloudTypes, (function (cloud) {
                                                                        return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                    value: cloud,
                                                                                    children: cloud
                                                                                  }, cloud);
                                                                      }))
                                                              })
                                                        ],
                                                        className: css.fieldRow
                                                      }),
                                                  JsxRuntime.jsx("div", {
                                                        children: JsxRuntime.jsxs("div", {
                                                              children: [
                                                                JsxRuntime.jsx(Label.make, {
                                                                      forId: domId$2,
                                                                      children: "Description"
                                                                    }),
                                                                JsxRuntime.jsx(Textarea.make, {
                                                                      id: domId$2,
                                                                      value: Belt_Option.getWithDefault(configuration.description, ""),
                                                                      rows: 3,
                                                                      onBlur: (function (param) {
                                                                          form.blurConfigurationDescription(configurationIndex);
                                                                        }),
                                                                      onChange: (function ($$event) {
                                                                          var value = ReactForm.value($$event);
                                                                          form.updateConfigurationDescription(configurationIndex, (function (input, value) {
                                                                                  return CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                return {
                                                                                                        key: configuration.key,
                                                                                                        startDate: configuration.startDate,
                                                                                                        contractLength: configuration.contractLength,
                                                                                                        rawStorageCapacity: configuration.rawStorageCapacity,
                                                                                                        diskType: configuration.diskType,
                                                                                                        connectivityMethod: configuration.connectivityMethod,
                                                                                                        getRequests: configuration.getRequests,
                                                                                                        getRequestsUnit: configuration.getRequestsUnit,
                                                                                                        putRequests: configuration.putRequests,
                                                                                                        putRequestsUnit: configuration.putRequestsUnit,
                                                                                                        transferOut: configuration.transferOut,
                                                                                                        transferOutUnit: configuration.transferOutUnit,
                                                                                                        region: configuration.region,
                                                                                                        cloudType: configuration.cloudType,
                                                                                                        description: value,
                                                                                                        features: configuration.features,
                                                                                                        accessTypes: configuration.accessTypes,
                                                                                                        certifications: configuration.certifications,
                                                                                                        additionalRequirements: configuration.additionalRequirements
                                                                                                      };
                                                                                              }));
                                                                                }), value);
                                                                          persistDraft([
                                                                                CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                        return {
                                                                                                key: configuration.key,
                                                                                                startDate: configuration.startDate,
                                                                                                contractLength: configuration.contractLength,
                                                                                                rawStorageCapacity: configuration.rawStorageCapacity,
                                                                                                diskType: configuration.diskType,
                                                                                                connectivityMethod: configuration.connectivityMethod,
                                                                                                getRequests: configuration.getRequests,
                                                                                                getRequestsUnit: configuration.getRequestsUnit,
                                                                                                putRequests: configuration.putRequests,
                                                                                                putRequestsUnit: configuration.putRequestsUnit,
                                                                                                transferOut: configuration.transferOut,
                                                                                                transferOutUnit: configuration.transferOutUnit,
                                                                                                region: configuration.region,
                                                                                                cloudType: configuration.cloudType,
                                                                                                description: value,
                                                                                                features: configuration.features,
                                                                                                accessTypes: configuration.accessTypes,
                                                                                                certifications: configuration.certifications,
                                                                                                additionalRequirements: configuration.additionalRequirements
                                                                                              };
                                                                                      })),
                                                                                context
                                                                              ]);
                                                                        })
                                                                    })
                                                              ],
                                                              className: Cx.cx([
                                                                    css.field,
                                                                    css.textAreaContainer
                                                                  ])
                                                            }),
                                                        className: css.fieldRow
                                                      }),
                                                  JsxRuntime.jsx(ProjectWizardPanel.CollapsableSection.make, {
                                                        title: "Features",
                                                        visibility: visibility$1,
                                                        toggleVisibility: (function () {
                                                            dispatch({
                                                                  TAG: "ToggleFeaturesVisibility",
                                                                  at: configurationIndex
                                                                });
                                                          }),
                                                        children: tmp$1
                                                      }),
                                                  JsxRuntime.jsx(ProjectWizardPanel.CollapsableSection.make, {
                                                        title: "Access Type",
                                                        visibility: visibility$2,
                                                        toggleVisibility: (function () {
                                                            dispatch({
                                                                  TAG: "ToggleAccessTypesVisibility",
                                                                  at: configurationIndex
                                                                });
                                                          }),
                                                        children: tmp$2
                                                      }),
                                                  JsxRuntime.jsx(ProjectWizardPanel.CollapsableSection.make, {
                                                        title: "Certifications",
                                                        visibility: visibility$3,
                                                        toggleVisibility: (function () {
                                                            dispatch({
                                                                  TAG: "ToggleCertificationsVisibility",
                                                                  at: configurationIndex
                                                                });
                                                          }),
                                                        children: tmp$3
                                                      }),
                                                  JsxRuntime.jsx(ProjectWizardPanel.CollapsableSection.make, {
                                                        title: "Additional Requirements",
                                                        visibility: visibility$4,
                                                        toggleVisibility: (function () {
                                                            dispatch({
                                                                  TAG: "ToggleAdditionalRequirementsVisibility",
                                                                  at: configurationIndex
                                                                });
                                                          }),
                                                        children: tmp$4
                                                      })
                                                ]
                                              })
                                        ],
                                        className: css.formPanelBodyContainer
                                      });
                                } else {
                                  tmp = null;
                                }
                                return JsxRuntime.jsx(ProjectWizardPanel.make, {
                                            title: configurationTitle(form, configurationIndex),
                                            visibility: visibility,
                                            toggleVisibility: (function () {
                                                dispatch({
                                                      TAG: "TogglePanelVisibility",
                                                      at: configurationIndex
                                                    });
                                              }),
                                            deleteControl: match.length !== 1 ? (function () {
                                                  form.removeConfiguration(configurationIndex);
                                                  dispatch({
                                                        TAG: "RemovePanel",
                                                        at: configurationIndex
                                                      });
                                                  persistDraft([
                                                        CreateCloudProjectIaasCloudStorageConfigurationForm.Configuration.remove(form.input, configurationIndex),
                                                        context
                                                      ]);
                                                }) : undefined,
                                            children: tmp
                                          }, configuration.key);
                              })),
                        JsxRuntime.jsx(Button.make, {
                              size: "SM",
                              color: "Teal",
                              onClick: (function (param) {
                                  dispatch("AddPanel");
                                }),
                              children: "+ Add One More Cloud Configuration"
                            })
                      ]
                    }),
                JsxRuntime.jsx(ProjectWizardNavigation.make, {
                      stepLabel: "Step 1 of 3",
                      controls: {
                        TAG: "PreviousAndNextAndContinueLater",
                        next: tmp,
                        previous: tmp$3,
                        continueLater: tmp$4
                      },
                      mobile: mobile
                    }),
                tmp$5
              ]
            });
}

var Configuration;

var make = CreateCloudProjectIaasCloudStorageConfiguration;

export {
  Configuration ,
  css ,
  domId ,
  configurationTitle ,
  Visibilities ,
  ValidateSaveAndContinueLaterForm ,
  Api$1 as Api,
  make ,
}
/* css Not a pure module */
